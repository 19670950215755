import React, { useEffect, useContext, useCallback } from 'react';
import { Builder } from '@builder.io/react';
import FunnelContext from '../../../context/FunnelContext';

function NextLayerTimer(props) {
  const {
    nextLayerId,
    timeDelay = 5000,
    insertQueryParam = true,
    queryParamString = 'layer'
  } = props;

  const { setCurrentLayer } = useContext(FunnelContext);

  const handleChangeLayer = useCallback(() => {
    setCurrentLayer(nextLayerId);
    if (insertQueryParam) {
      let params = new URLSearchParams(window.location.search);
      params.set(queryParamString, nextLayerId);

      const newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        '?' +
        params.toString();
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }, [nextLayerId, setCurrentLayer, insertQueryParam, queryParamString]);
  console.log(props);
  useEffect(() => {
    if (!Builder.isEditing) {
      setTimeout(() => handleChangeLayer(), timeDelay);
    }
  }, [nextLayerId, timeDelay, setCurrentLayer, handleChangeLayer]);

  if (!Builder.isEditing) return null;
  return (
    <center>
      <small>- - Next Layer Timer Component - -</small>
      <br />
      <strong>{nextLayerId}</strong>
    </center>
  );
}

export default NextLayerTimer;
